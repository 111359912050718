


//@import "browser.lib.evaluation-tool/scss/variables";
//@import "browser.lib.evaluation-tool/scss/components";
//@import "browser.lib.evaluation-tool/scss/mojo-ion";



//@include mat-core();
//
//
//
// Define the theme.
//$am-app-primary: mat-palette($mat-blue);
//$am-app-accent:  mat-palette($mat-green, A200, A100, A400);
//$am-app-theme:   mat-light-theme($am-app-primary, $am-app-accent);
//
//// Include the theme styles for only specified components.
//@include mat-core-theme($am-app-theme);
//@include mat-button-theme($am-app-theme);
//@include mat-checkbox-theme($am-app-theme);
//
//@include angular-material-theme($am-app-theme);

@import 'styles/ionic-angular/css/ionic.css';
// @import '../node_modules/ionic-angular/components/app/app.md';

@import 'browser.lib.evaluation-tool/scss/variables';

@import 'app/app';


@import 'browser.lib.evaluation-tool/scss/index';


//.question-boolean-dialog {
//
//
//  mat-dialog-container {
//    padding: 0;
//  }
//
//
//  background-color: rebeccapurple;
//
//  .mat-dialog-container {
//
//    background-color: green;
//  }
//}

.confirm-cancel-dialog .mat-dialog-container {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.0);
}
