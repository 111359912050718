

.mojo-title {


  text-align: center;

  img {
    background-color: transparent;
    height: 34px;
  }
}


//ion-footer ion-toolbar toolbar-background {
//
//  background-color: green;
//}

.toolbar-background-md {

  background-color: $v-colour-mm-purple;
}

//.toolbar-background-md {
//  background-color: #1C2E7B;
//}
