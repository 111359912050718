


// [Overriding Angular Material Size and Styling of md-dialog-container - Stack Overflow](https://stackoverflow.com/questions/46271898/overriding-angular-material-size-and-styling-of-md-dialog-container/46271998)

.help-dialog {
  .mat-dialog-container {

    padding: 0;
  }
}

