


.button-md-light {

  background-color: white;
  color: $v-colour-mm-purple;
}

.select-md {

  background-color: white;
  color: $v-colour-mm-purple;
}
