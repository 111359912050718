@use '@angular/material' as mat;



@import "node_modules/@angular/material/theming";
@import "variables";


@include mat.core();





$mm-primary: (
  50: #ede7f6,
  100: #d1c4e9,
  200: #b39ddb,
  300: #9575cd,
  400: #7e57c2,
  500: $mm-colour-primary,
  600: #5e35b1,
  700: #512da8,
  800: #4527a0,
  900: #311b92,
  A100: #b388ff,
  A200: #7c4dff,
  A400: #651fff,
  A700: #6200ea,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);


// Define the theme.
//$mm-app-primary: mat-palette($mat-deep-purple);
$mm-app-primary: mat.define-palette($mm-primary);

$mm-app-accent:  mat.define-palette(mat.$blue-gray-palette, A200, A100, A400);
$mm-app-theme:   mat.define-light-theme($mm-app-primary, $mm-app-accent);

// Include the theme styles for only specified components.
@include mat.core-theme($mm-app-theme);
@include mat.button-theme($mm-app-theme);
@include mat.checkbox-theme($mm-app-theme);

@include mat.all-component-themes($mm-app-theme);

// vvv [angular material - How to change color property of mat-slide-toggle/ overwrite CSS of component? - Stack Overflow](https://stackoverflow.com/questions/50838843/how-to-change-color-property-of-mat-slide-toggle-overwrite-css-of-component)

:host ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #9575cd;
}

:host ::ng-deep  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $mm-colour-primary;
}


// ^^^ [angular material - How to change color property of mat-slide-toggle/ overwrite CSS of component? - Stack Overflow](https://stackoverflow.com/questions/50838843/how-to-change-color-property-of-mat-slide-toggle-overwrite-css-of-component)
