



$mm-colour-primary: #370178;
$mm-colour-secondary: #5d358d;




//$v-colour-mojo-blue-dark: #05396C;
//$v-colour-mojo-pink: #D70069;


//$v-colour-mojo-cyan-light: #E6F4F0;
$v-colour-mojo-cyan-dark: #4BBAB9;


$v-colour-mm-purple: #452c87;
$v-colour-mm-purple-secondary: #bbb5d0;
$v-colour-mm-grey: #8a8e9a;
$v-colour-mm-background: #f1f0f6;


// deprecated ...

$v-colour-toolbar-background-color: $v-colour-mm-purple;
$v-colour-header-color: $v-colour-mm-purple;
//$v-colour-content-background: $v-colour-mojo-cyan-light;

$v-colour-general: $v-colour-mm-purple;
$v-colour-general-dark: $v-colour-mm-purple;

$v-colour-bedroom: $v-colour-mm-purple;
$v-colour-bedroom-dark: $v-colour-mm-purple;

$v-colour-bathroom: $v-colour-mm-purple;
$v-colour-bathroom-dark: $v-colour-mm-purple;


$width-phone: 500px;


$widget-background-color: #FFFFFFA0;
